<template>
  <div class="home h-100 container">
      <login />
  </div>
</template>

<script>
import Login from "../components/Login";
export default {
    name: 'LoginView',
    components: {
        Login
    }
}
</script>

<style>

</style>